
import { defineComponent } from 'vue';
import SimulationDataHelper from '@/helpers/SimulationDataHelper';
import globals from '../../../helpers/globals';
import FormButton from '../../../components/forms/Button.vue';
import AccountDataService from '../../../services/AccountDataService';

export default defineComponent({
  name: 'EmailHasBeenSent',
  mixins: [
    globals,
  ],
  components: {
    FormButton,
  },
  created() {
    const vm = this;

    /* Try to set profile if authenticated, otherwise, redirect user to home */
    this.setProfile(this.$store, (response: any) => { /* */ }, (response: any) => {
      this.redirectIfUnauthenticated(this.$router, this.$store, {});
    });

    /* LOAD DATA WHEN COMPONENT IS CREATED */
    this.$store.dispatch('isLoading', true);

    this.load((data: any) => {
      vm.$store.dispatch('setSelectedSimulation', data);

      vm.init();
    });
  },
  mounted() {
    /* Scroll page to top (case of mobile phones) */
    this.scrollToTop();
  },
  methods: {
    /* COMPONENT INITIALIZER */
    init() {
      /* FLAG STEP AS UNCOMPLETED */
      this.setStepAsCompleted(this, false);

      /* MOVE PROGRESS BAR TO VALUE */
      this.progress(this);

      /* SHOW SIDE AND NAV BAR IF USER FALLS DIRECTLY INTO CURRENT COMPONENT */
      this.$store.dispatch('toggleProgressBar', true);
      this.$store.dispatch('toggleTopBar', true);
      this.$store.dispatch('toggleSideBar', false);

      /* STOP LOADING */
      this.$store.dispatch('isLoading', false);
    },

    /* LOAD DATA THROUGH APIS */
    load(callback: any) {
      SimulationDataHelper.prepareComponentResult(
        this.$store,
        this.$route.params.simulation as string,
        callback,
      );
    },
    goToNextStep(event: any) {
      const vm = this;
      event.preventDefault();

      const token = this.getAccessToken();

      this.$store.dispatch('isLoading', true);

      AccountDataService.getFirstFile(token)
        .then((response: any) => {
          vm.$store.dispatch('setSelectedFile', response.data.data);
        })
        .catch((e: any) => { /* */ });
    },
    sendNewConfirmationEmail(event: any) {
      event.preventDefault();

      const token = this.getAccessToken();

      this.$store.dispatch('isLoading', true);

      AccountDataService.sendConfirmationEmail(token, this.$store.state.selected.simulation.outputs.pid as string)
        .then((response: any) => {
          this.$store.dispatch('isLoading', false);
        });
    },
  },
});
